<template>
<div>
  <div v-if="$parent.user" class="m-2">
    <b-alert show variant="danger" v-if="errorMessage">
      {{ errorMessage }}
    </b-alert>
    <div v-if="$parent.manager.promotedEventId">
      There is a mixer right now <button class="btn btn-primary" @click="this.joinEvent(this.$parent.manager.promotedEventId)">Join</button>
      <hr/>
    </div>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("events-join");

export default {
  data() {
    return {
      errorMessage: null,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let joinEventId = this.$route.params.eventid;
      if (this.$parent.user) {
        if (joinEventId)
          this.joinEvent(joinEventId);
      } else {
        log.log("No user, pushes to sign up with this url as redirect");
        if (joinEventId) {
          // Redirects to login https://stackoverflow.com/a/48271996/318839
          this.$router.push({
            name: "signup", 
            query: Object.assign({ redirect: this.$route.path }, this.$route.query),
            params: {
              eventId: joinEventId
            }});
        }
      }
    },
    async joinEvent(eventId) {
      log.log(`joining event ${eventId}`);
      try {
        this.$router.push({ 
          path: `/guest/event/${eventId}`, 
          query: this.$route.query,
        });
      } 
      catch (e) {
        this.errorMessage = `Could not join ${eventId}`;
      }
    },
  }
}
</script>

<style>

.event {
  margin: 2pt 0px 5pt 0px;
  padding: 2px;
  height: 60px;
}

.open {
  background: rgb(255, 247, 247);
}

.comingsoon {
  background: lightgray;
}

</style>